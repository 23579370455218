/**
 * @file It contains scripts for group page
 */
import { mapGetters, mapActions, mapMutations } from "vuex"
import ThemisInputAppend from "@/components/shared/input-append"
import ThemisInputAppendOuter from "@/components/shared/input-append-outer"
import ThemisDecision from "@/components/shared/decision"
import { getObjectsOnlyInFirstArray } from "@/utils"
import { getHeadersForTable, getFootersForTable } from "@/utils/table"
import { TABLE_NAMES } from "@/constants"

export default {
  name      : "Group",
  components: { ThemisInputAppend, ThemisInputAppendOuter, ThemisDecision },
  data() {
    return {
      name                      : undefined,
      description               : undefined,
      isNameChanged             : false,
      isDescriptionChanged      : false,
      isNameDuplicate           : undefined,
      selectedUsers             : undefined,
      isNameInputFocussed       : false,
      isDescriptionInputFocussed: false
    }
  },
  computed: {
    ...mapGetters({
      users                : "users/users",
      groups               : "groups/groups",
      groupsUsers          : "groups/groupsUsers",
      nameUpdateError      : "groups/nameUpdateError",
      isUpdatingName       : "groups/isUpdatingName",
      isNameUpdated        : "groups/isNameUpdated",
      isUpdatingDescription: "groups/isUpdatingDescription",
      isDescriptionUpdated : "groups/isDescriptionUpdated",
      isAddingUsers        : "groups/isAddingUsers",
      isRemovingUser       : "groups/isRemovingUser",
      isGroupRemoved       : "groups/isGroupRemoved",
      isRemovingGroup      : "groups/isRemovingGroup",
      isDefaultSsoEnabled  : "configurations/isDefaultSsoEnabled"
    }),
    group() {
      return this.groups.find(group => group.id === +this.$route.params.id)
    },
    groupUsers() {
      return this.users.filter(user => this.groupsUsers[this.group.id]?.includes(user.id))
    },
    usersForTable() {
      return this.groupUsers.map(user => ({
        id      : user.id,
        name    : user.name,
        jobTitle: user.jobTitle,
        email   : user.email,
        status  : user.enabled ? (user.lastLogin ? "130" : "131") : "129"
      }))
    },
    usersForSelection() {
      if(this.users) {
        const users = getObjectsOnlyInFirstArray(this.users, this.groupUsers, "id")
        return users.filter(user => user.enabled)
          .map(user => {
            return {
              value: user.id,
              text : user.name
            }
          })
      }
    },
    headersForTable() {
      const excludedColumns = this.isDefaultSsoEnabled ? ["status"] : undefined

      return getHeadersForTable(
        TABLE_NAMES.GROUP_USERS,
        this.$t.bind(this),
        excludedColumns
      )
    },
    footersForTable() {
      return getFootersForTable(TABLE_NAMES.GROUP_USERS, this.$t.bind(this))
    }
  },
  methods: {
    ...mapActions({
      removeGroup: "groups/removeGroup",
      updateGroup: "groups/updateGroup",
      removeUser : "groups/removeUser",
      addUsers   : "groups/addUsers",
      notify     : "shared/notify"
    }),
    ...mapMutations({
      resetGroupUpdateError: "groups/resetGroupUpdateError"
    }),
    resetName() {
      this.name = this.group.name
      this.resetGroupUpdateError(["name"])
    }
  },
  watch: {
    group: {
      handler: function(newValue) {
        if (!newValue) {
          this.$router.push({ name: "groups" })
        }
      }
    },
    "group.name": {
      immediate: true,
      handler  : function(newValue) {
        if (this.name !== newValue) {
          this.name = newValue
        } else {
          this.isNameChanged = false
        }
      }
    },
    "group.description": {
      immediate: true,
      handler  : function(newValue) {
        if (this.description !== newValue) {
          this.description = newValue
        } else {
          this.isDescriptionChanged = false
        }
      }
    },
    name: {
      immediate: true,
      handler  : function(newValue) {
        this.isNameChanged   = this.group?.name !== newValue
        this.isNameDuplicate = false
      }
    },
    description: {
      immediate: true,
      handler  : function(newValue) {
        this.isDescriptionChanged = this.group?.description !== newValue
      }
    },
    nameUpdateError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          if (newValue.field === "name" && newValue.type === "duplicate") {
            this.isNameDuplicate = true
            return
          }
        }
        this.isNameDuplicate = false
      }
    },
    isAddingUsers: {
      handler: function(newValue) {
        if (!newValue) {
          this.selectedUsers = undefined
        }
      }
    },
    isGroupRemoved: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type      : "success",
            text      : "433",
            parameters: {
              name: this.name
            }
          })
          this.$router.push({ name: "groups" })
        }
      }
    },
    isRemovingGroup: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.REMOVE_GROUP.pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS.REMOVE_GROUP.pActions[0].buttonProps.disabled = newValue
      }
    }
  }
}